import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import BrandList from "../components/brandList.js";
import CategoryList from "../components/categoryList.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "produkter"
    }}>{`Produkter`}</h1>
    <h2 {...{
      "id": "märken"
    }}>{`Märken`}</h2>
    <BrandList mdxType="BrandList"></BrandList>
    <h2 {...{
      "id": "kategorier"
    }}>{`Kategorier`}</h2>
    <CategoryList mdxType="CategoryList"></CategoryList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      